import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import gradient from "../assets/gradient-bg.png";
import { quotes } from "../constants";

export default function QuotesCarousel() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${gradient})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        py: 6,
      }}
    >
      <Container
        sx={{
          textAlign: "center",
          color: "white",
          maxWidth: "md",
        }}
      >
        <Typography variant="h2" gutterBottom>
          Climate Tech Testimonials
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4, color: "black" }}>
          Inspiring words from leaders shaping the future of our planet.
        </Typography>

        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 5000, // 5 seconds
            disableOnInteraction: false, // Keeps autoplay after user interaction
          }}
          spaceBetween={20}
          slidesPerView={1}
        >
          {quotes.map((quote, index) => (
            <SwiperSlide key={index}>
              <Card
                sx={{
                  background: "rgba(255, 255, 255, 0.3)",
                  backdropFilter: "blur(5px)",
                  padding: 3,
                  borderRadius: 3,
                  color: "black",
                  maxWidth: "500px", // Set max width
                  minHeight: "200px", // Set min height
                  margin: "0 auto", // Center align
                }}
              >
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ fontStyle: "italic", marginTop: 5 }} // Italic style for quotes
                  >
                    {quote.testimonial}
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    — {quote.name}
                  </Typography>
                  <Typography variant="body2">{quote.occupation}</Typography>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Box>
  );
}
