import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import HardwareRoundedIcon from '@mui/icons-material/HardwareRounded';
import YardRoundedIcon from '@mui/icons-material/YardRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import RocketRoundedIcon from '@mui/icons-material/RocketRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';

export const quotes = [
  {
    name: 'Bill Gates',
    occupation: 'Co-founder of Microsoft',
    testimonial:
      '"We need innovation to solve the climate crisis. Climate-tech companies are critical to turning ideas into action."',
  },
  {
    name: 'John Doerr',
    occupation: 'Venture Capitalist, Kleiner Perkins',
    testimonial:
      '"Speed and scale are essential in climate action. Entrepreneurs will lead us toward a net-zero future."',
  },
  {
    name: 'Paul Hawken',
    occupation: 'Environmentalist & Author',
    testimonial:
      '"Climate solutions are opportunities for humanity to redesign systems for a thriving future."',
  },
  {
    name: 'Satya Nadella',
    occupation: 'CEO of Microsoft',
    testimonial:
      '"Sustainability is the next big disruptor, and technology will be at its core."',
  },
  {
    name: 'Elon Musk',
    occupation: 'CEO of Tesla & SpaceX',
    testimonial:
      '"The faster we transition to sustainable energy, the better our future will be."',
  },
];

export const COURSE_STRUCTURE = {
  parts: [
    {
      id: 1,
      title: "Part 1: Understand the Space",
      modules: [
        { id: 1, title: "Module 1: Introduction" },
        { id: 2, title: "Module 2: The Problem" },
        { id: 3, title: "Module 3: Special Characteristics of Climate Tech Entrepreneurship" },
        { id: 4, title: "Module 4: Solution Sectors" },
        { id: 5, title: "Module 5: Political & Regulatory Frameworks" },

      ],
    },
    {
      id: 2,
      title: "Part 2: Start With Yourself",
      modules: [
        { id: 1, title: "Module 1: Self Assessment" },
        { id: 2, title: "Module 2: Founder Mindset" },
      ],
    },
    {
      id: 3,
      title: "Part 3: Lean Climate Tech Startup",
      modules: [
        { id: 1, title: "Module 1: Introduction to Human-Centered Methodologies" },
        { id: 2, title: "Module 2: Understand" },
        { id: 3, title: "Module 3: Empathize" },
        { id: 4, title: "Module 4: Define" },
        { id: 5, title: "Module 5: Ideation" },
        { id: 6, title: "Module 6: Minimum Viable Product" },
        { id: 7, title: "Module 7: Build, Measure, Learn" },

      ],
    },
    {
      id: 4,
      title: "Part 4: Pre-Seed Venture Building",
      modules: [
        { id: 1, title: "Module 1: Introduction" },
        { id: 2, title: "Module 2: Climate Business Modelling" },
        { id: 3, title: "Module 3: Finance & Carbon Accounting" },
        { id: 4, title: "Module 4: Team" },
        { id: 5, title: "Module 5: Fundraising" },
        { id: 6, title: "Module 6: Pitching" },
        { id: 7, title: "Module 7: Branding" },
        { id: 8, title: "Module 8: Go-to-Market Strategy" },
        { id: 9, title: "Module 9: Sales & Marketing" },
      ],
    },
  ],
};


export const consultancyHighlightsItems = [
  {
    icon: <FormatListBulletedRoundedIcon />,
    title: "Intrapreneurship Program Design",
    description:
      "Empower your employees to innovate with custom programs that foster climate-focused entrepreneurial thinking within your organization.",
  },
  {
    icon: <AccountTreeRoundedIcon />,
    title: "Climate Tech Strategy Workshops",
    description:
      "Collaborate on actionable strategies to align your business goals with climate innovation and sustainability trends.",
  },
  {
    icon: <TimelineRoundedIcon />,
    title: "Opportunity Assessment for Climate Innovation",
    description:
      "Identify and evaluate market opportunities for new climate tech products or services based on your company's strengths.",
  },
  {
    icon: <HardwareRoundedIcon />,
    title: "Prototyping and Rapid Experimentation",
    description:
      "Learn to build, test, and iterate climate tech solutions quickly, reducing risk while accelerating progress.",
  },
  {
    icon: <YardRoundedIcon />,
    title: "Sustainability Integration in Business Models",
    description:
      "Redesign or enhance your business models to integrate sustainability and carbon reduction at the core.",
  },
  {
    icon: <HandshakeRoundedIcon />,
    title: "Partnerships and Ecosystem Building",
    description:
      "Connect with startups, accelerators, and ecosystem partners to co-create solutions and expand your impact in the climate tech space.",
  },
];

export const academyHighlightsItems = [
  {
    icon: <TravelExploreRoundedIcon />,
    title: "Understand the Space",
    description:
      "Gain a deep understanding of the climate tech landscape, from key technologies to market trends and policy drivers.",
  },
  {
    icon: <FlagCircleRoundedIcon />,
    title: "Define Your Impact Mission",
    description:
      "Craft a clear, compelling mission statement that aligns your entrepreneurial goals with meaningful climate and nature impact.",
  },
  {
    icon: <CheckCircleOutlineRoundedIcon />,
    title: "Find Problem-Solution Fit",
    description:
      "Learn to identify pressing climate problems and design innovative solutions that address real needs effectively.",
  },
  {
    icon: <EuroRoundedIcon />,
    title: "Raise Funding",
    description:
      "Master the essentials of securing funding, from pitching to climate-focused investors to navigating grants and subsidies.",
  },
  {
    icon: <RocketRoundedIcon />,
    title: "Build Your Venture",
    description:
      "Discover practical tools and methodologies to structure, launch, and scale your climate tech startup.",
  },
  {
    icon: <RocketLaunchRoundedIcon />,
    title: "Find Product-Market Fit",
    description:
      "Validate and refine your product or service to meet market demands and achieve impactful, scalable adoption.",
  },
];
