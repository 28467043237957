import React from "react";
import Typography from "@mui/material/Typography";
import SubPageLayout from "./SubPageLayout";

const TermsOfService = () => (
  <SubPageLayout title="Terms of Service">
    <Typography variant="h4" component="h1" gutterBottom>
      Terms of Service
    </Typography>
    <Typography variant="h5" component="h1" gutterBottom>
      Effective Date: Jan 2025
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      Welcome to Climestart ("we," "our," or "us"). These Terms of Service
      ("Terms") govern your access to and use of our website climestart.com (the
      "Site") and related services, including consultancy services and our
      upcoming online learning platform (collectively, the "Services"). By
      accessing or using our Services, you confirm that you accept these Terms
      and agree to comply with them. If you do not agree, you must not use our
      Services.
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      1. Eligibility
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      You must be at least 18 years old to use our Services. By using our
      Services, you represent that you are legally capable of entering into
      binding contracts under EU law.
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      2. Services We Provide
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      a) Consultancy Services We provide consultancy services for climate tech
      entrepreneurship, including workshops, strategic advisory, and support for
      intrapreneurship programs. Specific terms for these services will be
      detailed in separate agreements where applicable. b) Online Learning
      Platform Our online learning platform, featuring courses and an AI
      assistant, will be available for individuals seeking to start or advance
      in climate tech entrepreneurship. Access to these services may require
      registration and payment.
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      3. User Accounts
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      To access certain features, you may need to create an account. You are
      responsible for ensuring your account details remain confidential and must
      notify us immediately of any unauthorized use. You agree to provide
      accurate and truthful information during registration.
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      4. Fees, Payment, and Refunds
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      a) Consultancy Services Fees and payment terms for consultancy services
      will be specified in separate contracts or invoices. b) Online Learning
      Platform Fees for courses or subscriptions will be displayed at the point
      of purchase. Refund Policy: If you are an EU consumer, you have the right
      to cancel a purchase within 14 days of payment for digital content,
      provided the content has not been accessed or downloaded.
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      5. Intellectual Property
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      All content provided through the Services, including text, images, videos,
      and software, is the intellectual property of Climestart or its licensors.
      You may not use, copy, or distribute any content without prior written
      permission, except as expressly permitted by applicable EU law.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      6. Prohibited Uses
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      You may not use our Services to: Engage in unlawful activities. Disrupt or
      interfere with the operation of the Site. Misrepresent your affiliation
      with Climestart. Reverse-engineer or misuse our proprietary technologies.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      7. Consumer Rights
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      If you are an EU consumer: You have the right to clear and transparent
      information about our Services and pricing. You may withdraw from certain
      agreements within the statutory 14-day cooling-off period, as outlined in
      Article 16 of the Consumer Rights Directive. These rights are in addition
      to any other rights provided by your national laws.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      8. Data Protection
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We process personal data in accordance with GDPR.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      9. Liability and Disclaimers
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We provide our Services "as is" without warranties, except those required
      by EU law. To the extent permitted by law, we are not liable for indirect
      or consequential damages, including loss of data or profits. Our total
      liability for any claim is limited to the amount you paid for the Service
      in question.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      10. Termination
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We reserve the right to suspend or terminate your access to the Services
      if you breach these Terms or applicable laws.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      11. Governing Law and Jurisdiction
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      These Terms are governed by the laws of Germany. Any disputes will be
      subject to the exclusive jurisdiction of the courts in Berlin, Germany.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      12. Changes to These Terms
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We may revise these Terms to reflect changes in our Services or legal
      requirements. Updates will be posted on this page with an updated
      "Effective Date."
    </Typography>
  </SubPageLayout>
);

export default TermsOfService;
