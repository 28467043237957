import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Container,
  Stack,
} from '@mui/material';

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        'service_xf8kddu', // Replace with your EmailJS service ID
        'template_uiskace', // Replace with your EmailJS template ID
        formData,
        '_8Sgr6uh4rc13LPwr' // Replace with your EmailJS public key
      )
      .then(
        () => {
          setLoading(false);
          setSubmitted(true);
        },
        (error) => {
          setLoading(false);
          console.error('Error sending contact request:', error);
        }
      );
  };

  const handleReset = () => {
    setFormData({ user_name: '', user_email: '', user_message: '' });
    setSubmitted(false);
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 4,
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: '#ffffff',
          textAlign: 'center',
          color: 'text.primary', 
        }}
      >
        {submitted ? (
          <Stack spacing={2} alignItems="center">
            <Typography variant="h4" sx={{ color: 'text.primary' }}>
              Thank you for your request!
            </Typography>
            <Typography variant="body1">
              We'll be in touch with you shortly.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleReset}>
              Make New Request
            </Button>
          </Stack>
        ) : (
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" gutterBottom>
              Contact Us
            </Typography>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                name="user_name"
                variant="outlined"
                value={formData.user_name}
                onChange={handleChange}
                placeholder="Full Name"
                required
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                name="user_email"
                type="email"
                variant="outlined"
                value={formData.user_email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                name="user_message"
                variant="outlined"
                value={formData.user_message}
                onChange={handleChange}
                placeholder="Message"
                multiline
                rows={6} 
              />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Send Message'}
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </Container>
  );
};

export default ContactForm;
