import React from "react";
import Typography from "@mui/material/Typography";
import SubPageLayout from "./SubPageLayout";

const Imprint = () => (
  <SubPageLayout title="Imprint">
    <Typography variant="h4" component="h1" gutterBottom>
      Imprint
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      Tassilo Weber<br/>Leinestr. 13<br/>12049 Berlin
    </Typography>

    <Typography variant="subtitle1" component="h2" gutterBottom>
      Redaktionell verantwortlich gemäß § 18 Abs. 2 MStV.<br/>Tassilo Weber<br/>
      Leinestr. 13<br/>12049 Berlin
    </Typography>
    
  </SubPageLayout>
);

export default Imprint;
