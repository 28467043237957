import React, { forwardRef } from "react";
import PropTypes from "prop-types"; 
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ContactForm from "./ContactForm";
import WaitlistForm from "./WaitlistForm";
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';

const Highlights = forwardRef(
  ({ items, headingText, subheadingText, formType }, ref) => {
    // Extracted icon logic
    let IconComponent = null;
    if (formType === "waitlist") {
      IconComponent = <SchoolRoundedIcon sx={{ fontSize: 80, color: "lightpink", marginBottom: 4 }} />;
    } else if (formType === "contact") {
      IconComponent = <TipsAndUpdatesRoundedIcon sx={{ fontSize: 80, color: "lightpink", marginBottom: 4 }} />;
    }

    return (
      <Box
        ref={ref} 
        id="highlights"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          color: "white",
          bgcolor: "grey.900",
        }}
      >
        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: "100%", md: "60%" },
              textAlign: { sm: "left", md: "center" },
            }}
          >
            {IconComponent}
            <Typography component="h2" variant="h2" gutterBottom>
              {headingText}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "white", whiteSpace: "pre-line", marginBottom: 4 }}
            >
              {subheadingText}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
                <Stack
                  direction="column"
                  component={Card}
                  spacing={1}
                  useFlexGap
                  sx={{
                    color: "inherit",
                    p: 3,
                    height: "100%",
                    borderColor: "hsla(220, 25%, 25%, 0.3)",
                    backgroundColor: "grey.800",
                  }}
                >
                  <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                  <div>
                    <Typography gutterBottom sx={{ fontWeight: "medium" }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "grey.400" }}>
                      {item.description}
                    </Typography>
                  </div>
                </Stack>
              </Grid>
            ))}
          </Grid>
          {/* Conditionally render the form */}
          <Box sx={{ mt: 4, width: "100%" }}>
            {formType === "contact" && <ContactForm />}
            {formType === "waitlist" && <WaitlistForm />}
          </Box>
        </Container>
      </Box>
    );
  }
);

// Define PropTypes for validation
Highlights.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  headingText: PropTypes.string.isRequired,
  subheadingText: PropTypes.string.isRequired,
  formType: PropTypes.oneOf(["contact", "waitlist"]).isRequired,
};

export default Highlights;
