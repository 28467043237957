import * as React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ColorModeIconDropdown from "../shared-theme/ColorModeIconDropdown";
import logo from "../assets/cs-logo-dot.svg";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: (theme.vars || theme).palette.divider,
  backgroundColor: theme.vars
    ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
    : alpha(theme.palette.background.default, 0.4),
  boxShadow: (theme.vars || theme).shadows[1],
  padding: "8px 12px",
}));

function AppAppBar({ consultancyRef, academyRef, aboutRef }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleScrollTo = (ref) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: "calc(var(--template-frame-height, 0px) + 28px)",
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}>
            <img
              src={logo}
              style={{ height: "35px", objectFit: "contain" }}
              alt="logo"
            />
            <Box sx={{ display: { xs: "none", md: "flex" } }}></Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 5,
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={() => handleScrollTo(consultancyRef)}
              sx={{
                color: 'white', 
              }}
            >
              Consulting
            </Button>
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={() => handleScrollTo(academyRef)}
              sx={{
                color: 'white', 
              }}
            >
              Academy
            </Button>
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={() => handleScrollTo(aboutRef)}
              sx={{
                color: 'white', 
              }}
            >
              About
            </Button>
            <ColorModeIconDropdown />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" }, gap: 1 }}>
            <ColorModeIconDropdown size="medium" />
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  top: "var(--template-frame-height, 0px)",
                },
              }}
            >
              <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>

                <MenuItem onClick={() => handleScrollTo(consultancyRef)}>
                  Consulting
                </MenuItem>
                <MenuItem onClick={() => handleScrollTo(academyRef)}>
                  Academy
                </MenuItem>
                <MenuItem onClick={() => handleScrollTo(aboutRef)}>
                  About
                </MenuItem>
                <Divider sx={{ my: 3 }} />
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}

// Add PropTypes to validate the props
AppAppBar.propTypes = {
  consultancyRef: PropTypes.object.isRequired,
  academyRef: PropTypes.object.isRequired,
  aboutRef: PropTypes.object.isRequired,
};

export default AppAppBar;
