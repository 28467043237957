import React, { forwardRef } from "react";
import { Box, Typography, Avatar, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Divider from "@mui/material/Divider";
import profile from "../assets/tassilo-weber.png";

const About = forwardRef((props, ref) => (
  <Box
    ref={ref}
    sx={{
      display: "flex",
      flexDirection: "column", // Ensures content stacks vertically
      alignItems: "center", // Centers items vertically
      justifyContent: "center", // Centers items horizontally
      padding: "20px",
      minHeight: "100vh", // Makes the box fill the viewport height
      width: { sm: "100%", md: "50%" },
      margin: "0 auto", // Ensures the box itself is centered
    }}
  >
    <Typography variant="h2" gutterBottom sx={{ marginBottom: 4 }}>
      About
    </Typography>

    <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
      Our Vision
    </Typography>
    <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
      We live in a decisive time for humanity. Climate and nature, as our
      fundamental resources, are in a critical state due to ongoing
      unsustainable business practices and political shifts towards right-wing
      totalitarianism weakening democratic dynamics, justice, and social
      equality.
    </Typography>
    <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
      At the same time, we have more talent, ambition as well as technological
      power and accessible infrastructure than ever, in order to change the
      course of things. And the most impactful approach to leverage the
      potential that could become humanity’s thriving future is the
      entrepreneurial one.
    </Typography>
    <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
      Therefore, our vision is a world where becoming a climate tech and nature
      tech entrepreneur is an accessible path for all kinds of people around
      the world. Those entrepreneurs are also empowered and incentivized to
      support each other for success, building an ecosystem to lower green
      premium-related price disadvantages and thereby advancing the whole
      climate tech market.
    </Typography>
    <Typography variant="subtitle1" sx={{ marginBottom: 4 }}>
      Starting with small steps, Climestart will contribute to making that
      vision a reality.
    </Typography>
    <Divider sx={{ marginBottom: 4 }} />
    <Typography variant="h3" gutterBottom sx={{ marginBottom: 4 }}>
      Founder
    </Typography>
    <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
      <Stack>
        <Avatar
          src={profile}
          alt="Tassilo Weber"
          sx={{ width: 120, height: 120, marginBottom: 2 }}
        />
        <IconButton
          color="inherit"
          size="medium"
          href="https://www.linkedin.com/in/tassilo-weber/"
          aria-label="LinkedIn"
          sx={{ alignSelf: "center" }}
        >
          <LinkedInIcon />
        </IconButton>
      </Stack>

      <Typography variant="subtitle1" sx={{ maxWidth: "800px" }}>
        Tassilo Weber is a serial impact entrepreneur, coach, startup mentor,
        and author with 11 years of professional experience. He co-founded and
        built two tech startups (one of which got acquired by a public biotech
        company), two innovation agencies, and has worked with numerous big
        companies, SMEs, accelerator programs, NGOs, and startups to build
        impactful innovations.
      </Typography>
    </Box>
  </Box>
));

export default About;
