import React from "react";
import Typography from "@mui/material/Typography";
import SubPageLayout from "./SubPageLayout";

const PrivacyPolicy = () => (
  <SubPageLayout title="Privacy Policy">
    <Typography variant="h4" component="h1" gutterBottom>
      Privacy Policy
    </Typography>
    <Typography variant="h5" component="h1" gutterBottom>
      Last Updated: Jan 2025
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      Climestart ("we," "our," or "us") values your privacy and is committed to
      protecting your personal data. This Privacy Policy explains how we
      collect, use, and process your personal data in compliance with the
      General Data Protection Regulation (GDPR).
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      1. Information We Collect
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      a) Personal Data You Provide to Us For the Waitlist: Name, email address,
      and optional additional information. For Consultancy Services: Name,
      company name, contact details, and information relevant to your inquiry or
      project. b) Automatically Collected Data IP address, device type, browser
      information, and website usage data (via cookies or similar technologies).
      c) Special Categories of Data We do not intentionally collect special
      categories of personal data (e.g., health, political opinions).
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      2. Legal Bases for Processing
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We process your personal data based on the following legal bases under
      GDPR: Consent: When you sign up for the waitlist or subscribe to
      newsletters. Contractual Necessity: To provide consultancy services. Legal
      Obligation: To comply with legal requirements (e.g., tax records).
      Legitimate Interest: To improve our services, maintain website security,
      and communicate relevant updates.
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      3. How We Use Your Data
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We use your data to: Manage waitlist registrations and provide updates
      about the online academy. Respond to consultancy inquiries and provide
      requested services. Improve the functionality and security of our website.
      Send promotional content, which you can opt out of at any time.
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      4. Data Retention
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We retain your personal data only as long as necessary to fulfill the
      purposes outlined in this policy or comply with legal obligations.
      Waitlist data: Retained until the platform is launched or you opt out.
      Consultancy data: Retained for the duration of the project and for legal
      record-keeping.
    </Typography>

    <Typography variant="h6" component="h2" gutterBottom>
      5. Your GDPR Rights
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      Under GDPR, you have the following rights: Access: Request access to your
      personal data. Rectification: Correct inaccurate or incomplete data.
      Erasure (Right to Be Forgotten): Request deletion of your data.
      Restriction: Request restriction of data processing in certain
      circumstances. Data Portability: Receive your data in a machine-readable
      format. Objection: Object to processing based on legitimate interests.
      Withdraw Consent: Withdraw consent at any time for processing based on
      consent.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      6. Data Sharing and Transfers
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We only share your data: With Service Providers: To operate our website
      and provide services (e.g., email delivery platforms). As Required by Law:
      To comply with legal obligations or protect our rights. We do not transfer
      your data outside the European Economic Area (EEA) unless appropriate
      safeguards (e.g., Standard Contractual Clauses) are in place.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      7. Cookies and Tracking Technologies
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We use cookies and similar technologies to enhance your experience. You
      can manage your cookie preferences through your browser settings.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      8. Data Security
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We implement robust technical and organizational measures to protect your
      personal data from unauthorized access, alteration, disclosure, or
      destruction.
    </Typography>
    <Typography variant="h6" component="h2" gutterBottom>
      9. Updates to This Policy
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      We may update this Privacy Policy to reflect changes in our practices or
      legal requirements. Updates will be posted on this page, and significant
      changes will be communicated to you.
    </Typography>
  </SubPageLayout>
);

export default PrivacyPolicy;
