import React, { useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Footer from "./components/Footer";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";
import Highlights from "./components/Highlights";
import About from "./components/About";
import QuotesCarousel from "./components/QuotesCarousel";
// import CourseManager from "./components/CourseManager";

import AppTheme from "./shared-theme/AppTheme";
import {
  consultancyHighlightsItems,
  academyHighlightsItems,
} from "./constants";

// Subpages
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Imprint from "./pages/Imprint";

function MainPage(props) {
  const consultancyRef = useRef(null);
  const academyRef = useRef(null);
  const aboutRef = useRef(null);

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar
        consultancyRef={consultancyRef}
        academyRef={academyRef}
        aboutRef={aboutRef}
      />
      <Hero 
      consultancyRef={consultancyRef}
      academyRef={academyRef}
      />
      <Highlights
        ref={consultancyRef}
        items={consultancyHighlightsItems}
        headingText="Climestart Consultancy"
        subheadingText="Tailored workshops and strategic guidance to ignite climate-focused innovation within your teams. Designed for companies ready to tackle climate and nature challenges from the inside."
        formType="contact"
      />
      <QuotesCarousel />
      {/*<CourseManager />*/}
      <Highlights
        ref={academyRef}
        items={academyHighlightsItems}
        headingText="Climestart Academy"
        subheadingText="We are building an online academy for individuals and teams who want to break into climate tech and nature tech entrepreneurship.\n\nWe empower you to build your own startup through online courses, tools, resources, and personal sessions."
        formType="waitlist"
      />
      <About ref={aboutRef} />
      <Footer />
    </AppTheme>
  );
}

function App(props) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage {...props} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/imprint" element={<Imprint />} />
      </Routes>
    </Router>
  );
}

export default App;
