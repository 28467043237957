import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import logo from "../assets/cs-logo.svg";
import videoFile from "../assets/gradient.mp4";


function Hero({ consultancyRef, academyRef }) {
 
  const handleScrollTo = (ref) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Box
    id="hero"
    sx={{
      position: "relative",
      width: "100%",
      height: "70vh",
      overflow: "hidden",
    }}
  >
    <video
      autoPlay
      loop
      muted
      playsInline
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        zIndex: -1,
      }}
    >
<source src={videoFile} type="video/mp4" />
Your browser does not support the video tag.
    </video>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
        >
          <img src={logo} style={{ height: '40px', objectFit: 'contain' }} alt="logo" />

          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
              textAlign: "center",
            }}
          >
            Empowering the Next Wave of Climate Innovators
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              color: "whitetext.secondary",
              width: { sm: "100%", md: "80%" },
            }}
          >
            Whether you're an individual looking to launch your climate tech
            venture or an organization aiming to foster intrapreneurship,
            Climestart is your partner for impactful innovation.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            useFlexGap
            sx={{
              pt: 2,
              width: { xs: "100%", sm: "350px" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleScrollTo(consultancyRef)}
              sx={{ minWidth: "120px" }}
            >
              Consultancy
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleScrollTo(academyRef)}
              sx={{ minWidth: "120px" }}
            >
              Academy
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default Hero;
