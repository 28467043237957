import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Footer from '../components/Footer'; // Adjust path to your Footer component
import logo from '../assets/cs-logo-dot.svg'; 

const SubPageLayout = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Back Button */}
      <IconButton
        onClick={() => navigate('/')}
        sx={{
          position: 'fixed',
          top: 16,
          left: 16,
          zIndex: 1300,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: 1,
          borderRadius: '50px',
          boxShadow: 1,
          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
        }}
      >
        <ArrowBackRoundedIcon fontSize="small" />
        <img src={logo} alt="Climestart Logo" style={{ height: '24px' }} />
      </IconButton>

      {/* Page Content */}
      <Box sx={{ display: "flex",
    flexDirection: "column", // Ensures content stacks vertically
    alignItems: "left", // Centers items vertically
    justifyContent: "center", // Centers items horizontally
    padding: "150px",
    minHeight: "100vh", // Makes the box fill the viewport height
    width: { sm: "100%", md: "50%" },
    margin: "0 auto", // Ensures the box itself is centered
    gap: 3,
     }}>{children}</Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default SubPageLayout;
